
import Vue from "vue";

import { authMapActions } from "@/store/modules/auth";
import { logoPath } from "@/helpers/generalHelpers";

export default Vue.extend({
  name: "confirmAccount",

  mounted() {
    const { email } = this.$route.query;
    if (email && typeof email === "string") {
      this.credentials.email = email;
      this.$router.replace("/claim").catch(() => {});
    }
    // this.validateConfirmAccount();
  },
  data() {
    return {
      error: "",
      message: "",
      loading: false,
      confirmRequestSent: false,
      credentials: {
        email: ""
      },
      resetToken: false,
      resetError: ""
    };
  },
  methods: {
    ...authMapActions(["requestClaimAccount", "requestConfirmAccount"]),
    getLogoPath() {
      return logoPath();
    },
    async initRequestConfirmAccount(): Promise<void> {
      this.loading = true;
      this.resetError = "";
      try {
        const { email } = this.credentials;
        const { message } = await this.requestClaimAccount({ email });
        this.message = message;
        this.confirmRequestSent = true;
      } catch (e) {
        if (e.message === "Missing required parameter: email") {
          this.$appNotifyError("Missing email address");
        } else {
          this.$appNotifyError(e.message);
        }
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    }
    // confirmAccountResetToken() {
    //   this.resetToken = true;
    // }
  }
});
